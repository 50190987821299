import RemoveIcon from '@mui/icons-material/Remove'
const Icon = () => {
  return (
    <RemoveIcon
      htmlColor='rgba(0, 0, 0, 0.54)'
      fontSize='medium'
    />
  )
}

export default Icon
