import React from 'react'
import { MenuItem, Select } from '@mui/material'
import styles from '@/components/Organisms/DIT/DITConnectionForm/style.module.scss'

const DisabledSelect: React.FC<{ name: string }> = ({ name }) => {
  return (
    <Select
      disabled={true}
      className={styles.Select}
      color='primary'
      size='small'
      value={name}
    >
      <MenuItem value={name}>{name}</MenuItem>
    </Select>
  )
}

export default DisabledSelect
