import React from 'react'
import type { Relations } from '@/components/Organisms/DIT/types'
import { useAppSelector } from '@/hooks'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'

const useConnectionForm = (relationShip: Relations | null) => {
  const devicesShipList = useAppSelector(
    ditSliceSelectors.stateRelationShipDevices
  )
  const mergedDevicesList = [...devicesShipList]
  if (
    relationShip &&
    !devicesShipList.find((item) => item.id === relationShip?.deviceId)
  ) {
    mergedDevicesList.push({
      id: relationShip.deviceId,
      name: relationShip.deviceName,
    })
  }
  const relationEventsList = useAppSelector(
    ditSliceSelectors.stateRelationShipEvents
  )

  return {
    devicesList: mergedDevicesList,
    relationList: relationEventsList,
  }
}

export default useConnectionForm
