import React from 'react'
import { LoadingButton } from '@mui/lab'
import {
  useCreateCopyProgramMutation,
  useEditRelationsMutation,
  useGetDitsQuery,
  useSetActiveDitMutation,
} from '@/components/Organisms/DIT/service'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '@/hooks'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
import { LoaderFullScreen } from '@/ui'
import DialogChanges from '@/components/Dialog/DialogChanges'
import { notifyError, notifySuccess } from '@/components'

const BtnSaveRelations: React.FC = () => {
  const { id } = useParams()
  const activeProgramId = useAppSelector(
    ditSliceSelectors.stateDitActiveProgram
  )
  const relationForm = useAppSelector(ditSliceSelectors.stateRelationForm)
  const [editRelations, { isLoading: relationsLoading }] =
    useEditRelationsMutation()
  const hasUnsavedChanges = useAppSelector(ditSliceSelectors.stateHasChanges)
  const { refetch } = useGetDitsQuery(Number(id))

  const [modalSetDit, setModalSetDit] = React.useState(false)
  const [setActiveDit, { isLoading: setActiveIsLoading }] =
    useSetActiveDitMutation()

  const approvedSendDit = async () => {
    if (activeProgramId) {
      await setActiveDit({
        deviceId: Number(id),
        programId: activeProgramId,
      })
    }
    setModalSetDit(false)
  }

  const handleActiveProgram = () => {
    setModalSetDit(true)
  }

  const handleSaveRelations = async () => {
    if (!relationForm?.eventsList.length) {
      notifyError('Ошибка. События не выбраны.')
      return
    }
    if (!relationForm?.sourceId || relationForm?.sourceId === -1) {
      notifyError('Ошибка. Источник не выбран.')
      return
    }
    if (activeProgramId && relationForm) {
      const res = await editRelations({
        deviceId: Number(id),
        programId: activeProgramId,
        relationShipDeviceId: relationForm.sourceId,
        relationShipEvents: relationForm.eventsList,
      })
      // @ts-ignore
      if (res?.data?.success) {
        refetch()
      }
    }
  }

  return (
    <>
      <LoaderFullScreen open={relationsLoading} />
      <LoadingButton
        disabled={!hasUnsavedChanges}
        variant={'contained'}
        loading={false}
        onClick={handleSaveRelations}
      >
        Сохранить
      </LoadingButton>
    </>
  )
}

export default BtnSaveRelations
