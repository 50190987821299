import { Autocomplete, TextField, InputLabel } from '@mui/material'
import Switch from './Switch'
import { useActions, useAppSelector } from '@/hooks'
import FormControl from '@mui/material/FormControl'
import DisabledSelect from './DisabledSelect'
import styles from './style.module.scss'
import { MenuItem, Select } from '@mui/material'
import React from 'react'
import type { Program } from '@/components/Organisms/DIT/types'
import useConnectionForm from './useConnectionForm'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Checkbox from '@mui/material/Checkbox'
type OptionType = {
  name: string
  id: number
}
type Props = { programId: number } & Pick<Program, 'relationShip'>
const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

const DITConnectionForm: React.FC<Props> = ({ relationShip, programId }) => {
  const unsavedRelations = useAppSelector(
    ditSliceSelectors.stateUnsavedRelations
  )
  const [relationValue, setRelationValue] = React.useState<number | undefined>(
    0
  )
  // console.log('relationShip', relationShip)
  // const [switchState, setSwitchState] = React.useState(Boolean(relationShip))
  const { devicesList, relationList } = useConnectionForm(relationShip)
  const { ditConnectCheckBox, setConnectionForm, ditSetUnsavedChanges } =
    useActions()
  const form = useAppSelector(ditSliceSelectors.stateRelationForm)
  // console.log(4444, form)
  // React.useEffect(() => {
  //   console.log(222, unsavedRelations)
  // }, [unsavedRelations])
  React.useEffect(() => {
    console.log('Effect form reset', relationShip, devicesList)
    //const relationId = relationShip?.deviceId || devicesList[0]?.id

    setRelationValue(relationShip?.deviceId || 0)
    setEventsValue(getEvents())
  }, [programId, relationShip, form?.switchState])

  const getEvents = () => {
    if (!relationShip) return []
    return relationList.filter((item) => relationShip?.events.includes(item.id))
  }
  const [eventsValue, setEventsValue] =
    React.useState<OptionType[]>(getEvents())

  const handleChangeRelation = (e: any) => {
    setRelationValue(e.target.value)
    ditSetUnsavedChanges(true)
    setConnectionForm({
      switchState: true,
      eventsList: eventsValue.map((item) => item.id),
      sourceId: e.target.value,
    })
  }

  const handleEventsChange = (options: OptionType[]) => {
    const uniqueValuesIds = [3, 4, 5, 6]
    let newOptions: any[] = []
    setEventsValue((prevState) => {
      const hasUniqueValuePrev = prevState.find((item) =>
        uniqueValuesIds.includes(item.id)
      )
      const hasUniqueValue = options.findLast((item) =>
        uniqueValuesIds.includes(item.id)
      )
      if (hasUniqueValuePrev && hasUniqueValue) {
        newOptions = options.filter(
          (item) => !uniqueValuesIds.includes(item.id)
        )
        newOptions = [...newOptions, hasUniqueValue]
      } else {
        newOptions = options
      }
      return [...newOptions]
    })
    ditSetUnsavedChanges(true)
    // if (relationValue) {
    setConnectionForm({
      switchState: true,
      eventsList: newOptions.map((item) => item.id),
      sourceId: relationValue || -1,
    })
    // }
  }
  console.log('relationValue', relationValue)
  return (
    <>
      <div className={styles.Wrapper}>
        <Switch relationShip={relationShip} />
        <FormControl
          className={styles.Item}
          sx={{ width: '220px' }}
        >
          <DisabledSelect name={'Метеостанция'} />
        </FormControl>
        <FormControl
          className={styles.Item}
          sx={{ width: '180px' }}
        >
          {form && form.switchState ? (
            <>
              <InputLabel id='test-select-label'>Источник</InputLabel>
              <Select
                // className={styles.Select}
                color='primary'
                size='small'
                label={'Источник'}
                labelId='test-select-label'
                value={relationValue}
                //defaultValue={relationValue}
                onChange={handleChangeRelation}
              >
                {devicesList.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          ) : (
            <DisabledSelect name={'Источник'} />
          )}
        </FormControl>
        <FormControl
          className={styles.Item}
          sx={{ flexGrow: 1 }}
        >
          {form && form.switchState ? (
            <>
              <Autocomplete
                multiple
                id='checkboxes-tags-demo'
                options={relationList}
                size='small'
                value={eventsValue}
                disableClearable={true}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue: OptionType[]) => {
                  handleEventsChange(newValue)
                }}
                renderOption={(props, option, { selected }) => {
                  // @ts-ignore
                  const { key, ...optionProps } = props
                  return (
                    <li
                      key={key}
                      {...optionProps}
                    >
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )
                }}
                className={styles.Item}
                // renderTags={(value, getTagProps) => {
                //   console.log(654, value)
                //   return (
                //     <span style={{ background: 'red' }}>
                //       {value.map((item) => (
                //         <span key={item.id}>{item.name}</span>
                //       ))}
                //     </span>
                //   )
                // }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='События'
                  />
                )}
              />
            </>
          ) : (
            <DisabledSelect name={'Событие'} />
          )}
        </FormControl>
      </div>
      {/*<p>{JSON.stringify(form)}</p>*/}
    </>
  )
}

export default DITConnectionForm
