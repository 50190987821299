import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from '@/components/Organisms/DIT/DITTree/DITTree.module.scss'
import IconEditText from '@/components/Atoms/Icons/IconEditText'
import IconAddImage from '@/components/Atoms/Icons/IconAddImage'
import { IconButton } from '@mui/material'
import IconDelete from '@/components/Atoms/Icons/IconDelete'
import type {
  Program,
  SlideConstructor,
} from '@/components/Organisms/DIT/types'
import { useActions, useAppSelector } from '@/hooks'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
type Props = {
  program: Program
  editMode: boolean
  // item: SlideConstructor
  // programId: number
  // slideId: number
}
const DITRadio: React.FC<Props> = ({ program, editMode }) => {
  const activeProgram = useAppSelector(ditSliceSelectors.stateDitActiveProgram)
  const programs = useAppSelector(ditSliceSelectors.stateDitPrograms)
  // console.log(programs)
  const switchReduxState = useAppSelector(
    ditSliceSelectors.stateConnectCheckBox
  )
  const handleCheckBox = () => {}
  const handleRadio = () => {}
  return null
  // return (
  //   <>
  //     {program?.active ? (
  //       <input
  //         type='checkbox'
  //         onChange={handleCheckBox}
  //         disabled={editMode}
  //       />
  //     ) : (
  //       <input
  //         type='radio'
  //         onChange={handleRadio}
  //         disabled={editMode}
  //       />
  //     )}
  //   </>
  // )
}

export default DITRadio
