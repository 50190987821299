import React, { useEffect } from 'react'
import { LoadingButton } from '@mui/lab'
import {
  useGetDitsQuery,
  useUpdateDitsMutation,
} from '@/components/Organisms/DIT/service'
import {
  getActiveProgram,
  removeIdForNewPrograms,
} from '@/components/Organisms/DIT/helpers'
import { useParams } from 'react-router-dom'
import { useActions, useAppSelector } from '@/hooks'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
import { Box } from '@mui/material'
import { LoaderFullScreen } from '@/ui'
import BtnMakeCopy from '@/components/Organisms/DIT/SaveButtons/BtnMakeCopy'

const SaveButtons = () => {
  const { id } = useParams()
  const { ditSetActiveProgram } = useActions()
  const activeProgramId = useAppSelector(
    ditSliceSelectors.stateDitActiveProgram
  )
  const hasUnsavedChanges = useAppSelector(ditSliceSelectors.stateHasChanges)
  const [saveDIts, { isLoading: saveIsLoading }] = useUpdateDitsMutation()
  const programsState = useAppSelector(ditSliceSelectors.stateDitSlice)
  const { refetch } = useGetDitsQuery(Number(id))
  const activeProgram = getActiveProgram(
    activeProgramId,
    programsState.programList
  )

  const onSubmit = async () => {
    const programs = programsState.programList
    await saveDIts({
      programs,
      deviceId: Number(id),
      activeProgramIndex: activeProgram?.index || 1,
    })
  }
  const onCancel = () => {
    refetch()
    ditSetActiveProgram(activeProgramId)
  }
  return (
    <Box
      display='flex'
      justifyContent='end'
      gap={2}
      mt={5}
    >
      <LoaderFullScreen open={saveIsLoading} />
      <BtnMakeCopy newName={activeProgram?.slides?.length} />
      <LoadingButton
        disabled={!hasUnsavedChanges}
        variant='contained'
        autoFocus
        loading={false}
        onClick={onSubmit}
      >
        Сохранить
      </LoadingButton>
      <LoadingButton
        disabled={!hasUnsavedChanges}
        variant={'outlined'}
        autoFocus
        loading={false}
        onClick={onCancel}
      >
        Отменить
      </LoadingButton>
    </Box>
  )
}

export default SaveButtons
