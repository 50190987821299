import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { useActions, useAppSelector } from '@/hooks'

import styles from './style.module.scss'
import React, { useEffect } from 'react'
import type { Program } from '@/components/Organisms/DIT/types'
import { LoaderFullScreen } from '@/ui'
import {
  useEditRelationsMutation,
  useGetDitsQuery,
} from '@/components/Organisms/DIT/service'
import { useParams } from 'react-router-dom'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'

type Props = {
  // programId: number
  // switchState: boolean
  // setSwitchState: (a: boolean) => void
} & Pick<Program, 'relationShip'>

const MySwitch: React.FC<Props> = ({ relationShip }) => {
  const { id } = useParams()
  const { refetch } = useGetDitsQuery(Number(id))
  const { ditSetUnsavedRelations } = useActions()
  const switchReduxState = useAppSelector(
    ditSliceSelectors.stateConnectCheckBox
  )

  const activeProgramId = useAppSelector(
    ditSliceSelectors.stateDitActiveProgram
  )
  const { ditConnectCheckBox, setConnectionForm, ditSetUnsavedChanges } =
    useActions()

  const [editRelations, { isLoading: relationsLoading }] =
    useEditRelationsMutation()
  const [switchState, setSwitchState] = React.useState(false)
  const form = useAppSelector(ditSliceSelectors.stateRelationForm)
  useEffect(() => {
    if (form) {
      setSwitchState(form?.switchState)
    } else {
      setSwitchState(false)
    }
  }, [form])

  const handleSwitch = async (e: React.ChangeEvent) => {
    if (switchState) {
      console.log('disabling', relationShip)
      setConnectionForm(null)
      if (activeProgramId && relationShip) {
        const res = await editRelations({
          deviceId: Number(id),
          programId: activeProgramId,
          relationShipDeviceId: -1,
          relationShipEvents: [],
        })
      }
      // @ts-ignore
      // if (res?.data?.success) {
      //   refetch()
      //   // ditSetUnsavedRelations(false)
      // }
      refetch()
    } else {
      setConnectionForm({ switchState: true, eventsList: [], sourceId: -1 })
      ditSetUnsavedChanges(true)
    }

    // setConnectionForm()

    // const val = e.target?.checked as boolean
    // if (switchState) {
    //   // if disabling
    //   setSwitchState(false)
    //   if (relationShip && activeProgramId) {
    //     const res = await editRelations({
    //       deviceId: Number(id),
    //       programId: activeProgramId,
    //       relationShipDeviceId: -1,
    //       relationShipEvents: [],
    //     })
    //     // @ts-ignore
    //     if (res?.data?.success) {
    //       refetch()
    //       ditSetUnsavedRelations(false)
    //     }
    //   }
    // } else {
    //   setSwitchState(true)
    //   ditSetUnsavedRelations(true)
    // }
    // ditConnectCheckBox(!switchState)
    // setSwitchState(!switchState)
  }
  // const handleSwitch = async () => {
  //   if (switchState) {
  //     // if disabling
  //     setSwitchState(false)
  //     if (relationShip) {
  //       const res = await editRelations({
  //         deviceId: Number(id),
  //         programId: programId,
  //         relationShipDeviceId: -1,
  //         relationShipEvents: [],
  //       })
  //       // @ts-ignore
  //       if (res?.data?.success) {
  //         refetch()
  //         ditSetUnsavedRelations(false)
  //       }
  //     }
  //   } else {
  //     // alert('unsaved changes')
  //     setSwitchState(true)
  //     ditSetUnsavedRelations(true)
  //   }
  // }
  return (
    <>
      <LoaderFullScreen open={relationsLoading} />
      <FormControlLabel
        control={
          <Switch
            onChange={handleSwitch}
            color={'success'}
            checked={switchState}
          />
        }
        label='Связь'
        className={styles.Item}
        sx={{ width: '100px' }}
      />
    </>
  )
}

export default MySwitch
