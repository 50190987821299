import car from '@/assets/img/car.png'
import redCar from '@/assets/img/car_red.png'
import cloudBlue from '@/assets/img/cloud-blue.png'
import cloudGray from '@/assets/img/cloud-gray.png'
import cloudRed from '@/assets/img/cloud-red.png'
import electrical from '@/assets/img/electricalBlue.png'
import redError from '@/assets/img/error-red.png'
import battary100 from '@/assets/img/full-battery.png'
import signal100 from '@/assets/img/full-connection.png'
import yellowError from '@/assets/img/icons-error.png'

import battary0 from '@/assets/img/low-battery.png'
import battary50 from '@/assets/img/medium-battery.png'
import signal50 from '@/assets/img/medium-connection.png'
import signal0 from '@/assets/img/no-connection.png'
import proc from '@/assets/img/processor.png'
import { DetailUnit, UnitsMapType } from '@/modules/maps/types/unitsMapType'

import { signal, voltage12, voltage24, VoltageEnum } from '@/utils'
import { TypeUltraShortTermForecastId } from '@/utils/meteo'

export const balloonYellow = (
  isError: boolean,
  isLoading: boolean,
  unitData: DetailUnit | UnitsMapType
): string => {
  return `<p style='position: absolute; top: 34%; left: 30%; display: ${isLoading ? 'block' : 'none'}'>Загрузка данных...</p>
          <p style='position: absolute; top: 34%; left: 30%; display: ${isError ? 'block' : 'none'}'>Ошибка</p>
           <div style='${isLoading ? 'filter: blur(3px);' : 'unset'}; ${isError ? 'display: none' : 'block'};'>
             <a class='balloon-link' href=/${unitData?.typeId === 7 ? 'photos' : 'devices'}/${unitData?.id}>${unitData?.type} ${unitData?.name}</a>
             <p>${unitData?.address}</p>
             <div style='display: flex; gap: 10px; align-items: center'>
               <img class="img-balloon" src="${yellowError}" height="40" width="40" alt='${unitData?.name}'>
               <p style='color: dodgerblue'>${unitData?.errorMessage ?? 'Потеря связи с модулем'}</p>
             </div>
           </div>`
}

export const balloonRed = (
  isError: boolean,
  isLoading: boolean,
  unitData: DetailUnit | UnitsMapType
): string => {
  return `<p style='position: absolute; top: 34%; left: 30%; display: ${isLoading ? 'block' : 'none'}'>Загрузка данных...</p>
          <p style='position: absolute; top: 34%; left: 30%; display: ${isError ? 'block' : 'none'}'>Ошибка</p>
           <div style='${isLoading ? 'filter: blur(3px);' : 'unset'}; ${isError ? 'display: none' : 'block'};'>
             <a class='balloon-link' href=/${unitData?.typeId === 7 ? 'photos' : 'devices'}/${unitData?.id}>${unitData?.type} ${unitData?.name}</a>
             <p>${unitData?.address}</p>
             <div style='display: flex; gap: 10px; align-items: center'>
               <img class="img-balloon" src="${redError}" height="40" width="40" alt='${unitData?.name}'>
               <p style='color: dodgerblue'>${unitData?.errorMessage ?? 'Система не работает'}</p>
             </div>
           </div>`
}

export const balloonGreen = (
  isError: boolean,
  isLoading: boolean,
  unitData: DetailUnit | UnitsMapType
): string => {
  return `<p style='position: absolute; top: 34%; left: 30%; display: ${isLoading ? 'block' : 'none'}'>Загрузка данных...</p>
          <p style='position: absolute; top: 34%; left: 30%; display: ${isError ? 'block' : 'none'}'>Ошибка</p>
           <div style='${isLoading ? 'filter: blur(3px);' : 'filter: unset;'}; ${isError ? 'display: none' : 'display: block;'};'>
             <a class='balloon-link' href=/${unitData?.typeId === 7 ? 'photos' : 'devices'}/${unitData?.id}>${unitData.type} ${unitData?.name}</a>
             <p>${unitData?.address}</p>
             <div class="img-container" style='${
               unitData?.smart ||
               unitData?.typeId === 7 ||
               unitData?.typeId === 9 ||
               unitData?.typeId === 8
                 ? 'display: flex'
                 : 'display: none'
             }; gap: 15px; align-items: center; justify-content: center; width: 100%'>
               <div style='${
                 unitData?.typeId === 7 ||
                 unitData?.typeId === 8 ||
                 unitData?.typeId === 9
                   ? 'display: none'
                   : 'display: flex'
               }; gap: 5px; align-items: center;'>
                 <img class="img-balloon" src="${proc}" height="30" width="30" alt='${unitData?.name}'>
                 <p style='color: dodgerblue; font-size: 16px'>${unitData?.countModules}</p>
               </div>
               <div style='${
                 unitData?.typeId === 7 ||
                 unitData?.typeId === 8 ||
                 unitData?.typeId === 9
                   ? 'display: none'
                   : 'display: flex'
               }; gap: 5px; align-items: center; justify-content: center'>
                 <img class="img-balloon" src="${
                   signal(unitData?.signal ?? 0) === 'low'
                     ? signal0
                     : signal(unitData?.signal ?? 0) === 'medium'
                       ? signal50
                       : signal(unitData?.signal ?? 0) === 'high'
                         ? signal100
                         : signal0
                 }" height="30" width="30" alt='${unitData?.name}'>
                 <p style='color: dodgerblue; font-size: 16px'>${unitData?.signal}%</p>
               </div>
               <div style='${
                 unitData?.typeId === 7 ||
                 unitData?.typeId === 8 ||
                 unitData?.typeId === 9
                   ? 'display: none'
                   : 'display: flex'
               }; gap: 5px; align-items: center; justify-content: center'>
                 <img class="img-balloon" src="${
                   unitData?.is220v
                     ? electrical
                     : (unitData?.voltage ?? 0) <= VoltageEnum.min
                       ? voltage12(unitData?.voltage ?? 0) === 'low'
                         ? battary0
                         : voltage12(unitData?.voltage ?? 0) === 'medium'
                           ? battary50
                           : voltage12(unitData?.voltage ?? 0) === 'high'
                             ? battary100
                             : battary0
                       : (unitData?.voltage ?? 0) > VoltageEnum.min &&
                           (unitData?.voltage ?? 0) <= VoltageEnum.max
                         ? voltage24(unitData?.voltage ?? 0) === 'low'
                           ? battary0
                           : voltage24(unitData?.voltage ?? 0) === 'medium'
                             ? battary50
                             : voltage24(unitData?.voltage ?? 0) === 'high'
                               ? battary100
                               : battary0
                         : battary0
                 }" height="30" width="30" alt='${unitData?.name}'>
                 <p style='color: dodgerblue; font-size: 16px;'>${Number(unitData?.voltage).toFixed(2)}В</p>
               </div>
               <div style='display: flex; gap: 5px; align-items: center;'>
                 <img class="img-balloon" src="${
                   unitData?.connectionStatus === 1
                     ? cloudBlue
                     : unitData?.connectionStatus === 2
                       ? cloudGray
                       : unitData?.connectionStatus === 3
                         ? cloudRed
                         : cloudRed
                 }" height="30" width="30" alt='${unitData?.name}'>
                 <p style='color: dodgerblue; font-size: 16px'>${
                   unitData?.connectionStatus === 1
                     ? 'В сети'
                     : unitData?.connectionStatus === 2
                       ? 'Не в сети'
                       : unitData?.connectionStatus === 3
                         ? 'Нет соединения'
                         : ''
                 }</p>
               </div>
             </div>
             <div style='display: ${unitData.typeId === 4 && unitData?.ultraShortTermForecastId > 1 ? 'flex' : 'none'}; gap: 5px; align-items: center;'>
               <img class="img-balloon" src="${unitData?.ultraShortTermForecastId === 3 ? redCar : car}" height="30" width="30" alt='${unitData?.name}'>
               <p>${TypeUltraShortTermForecastId[unitData?.ultraShortTermForecastId as keyof typeof TypeUltraShortTermForecastId]}</p>
</div>
           </div>`
}
