import styles from '@/components/Organisms/DIT/DITTree/DITTree.module.scss'
import Tooltip from '@mui/material/Tooltip'
import React, { FC } from 'react'
import {
  ditSliceReducer,
  ditSliceSelectors,
} from '@/components/Organisms/DIT/store'
import { useAppSelector } from '@/hooks'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import IconAddDoc from '@/components/Atoms/Icons/IconAddDoc'

type Props = {
  name: string
  id: number
}
const DITProgramState: FC<Props> = ({ name, id }) => {
  const status = useAppSelector(ditSliceSelectors.stateDitStatus)
  const isTarget = status?.id === id
  const hasError = isTarget && !status?.lastVersion
  return (
    <span className={styles.ProgramState}>
      {isTarget ? (
        <PlayCircleIcon
          htmlColor='rgba(0, 0, 0, 0.54)'
          fontSize='medium'
        />
      ) : (
        <IconAddDoc />
      )}
      {name}
      {hasError && (
        <Tooltip
          title='Отредактированная программа не отправлена'
          placement='top'
        >
          <span className={styles.Asterisk}>*</span>
        </Tooltip>
      )}
    </span>
  )
}

export default DITProgramState
