import { FC } from 'react'
import image from '@/assets/img/vlazh.png'
import { TypeIcon } from '@/components/Atoms/Icons/types'

const Icon: FC<TypeIcon> = ({ width = 30, height = 30 }) => {
  return (
    <img
      src={image}
      alt='Humidity'
      width={width}
      height={height}
    />
  )
}

export default Icon
