import React from 'react'
import { LoadingButton } from '@mui/lab'
import {
  useCreateCopyProgramMutation,
  useSetActiveDitMutation,
} from '@/components/Organisms/DIT/service'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '@/hooks'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
import { LoaderFullScreen } from '@/ui'
import DialogChanges from '@/components/Dialog/DialogChanges'
import { notifySuccess } from '@/components'

const BtnSendToDit: React.FC = () => {
  const { id } = useParams()
  const activeProgramId = useAppSelector(
    ditSliceSelectors.stateDitActiveProgram
  )
  const [modalSetDit, setModalSetDit] = React.useState(false)
  const [setActiveDit, { isLoading: setActiveIsLoading }] =
    useSetActiveDitMutation()

  const approvedSendDit = async () => {
    if (activeProgramId) {
      const res = await setActiveDit({
        deviceId: Number(id),
        programId: activeProgramId,
      })
      // @ts-ignore
      if (res?.data?.success) {
        notifySuccess(`Программа была удачно отправленна.`)
      }
    }
    // setModalSetDit(false)
  }

  const handleActiveProgram = () => {
    setModalSetDit(true)
  }

  return (
    <>
      <LoaderFullScreen open={setActiveIsLoading} />
      {/*<DialogChanges*/}
      {/*  open={modalSetDit}*/}
      {/*  loading={setActiveIsLoading}*/}
      {/*  handleAgree={approvedSendDit}*/}
      {/*  handleClose={() => setModalSetDit(false)}*/}
      {/*  handleDisAgree={() => setModalSetDit(false)}*/}
      {/*  message={'Отправить программу на ДИТ'}*/}
      {/*  btnSaveTitle={'Да'}*/}
      {/*  btnCancelTitle={'Нет'}*/}
      {/*/>*/}

      <LoadingButton
        variant={'contained'}
        loading={false}
        onClick={approvedSendDit}
        disabled={setActiveIsLoading}
      >
        Отправить
      </LoadingButton>
    </>
  )
}

export default BtnSendToDit
