import * as React from 'react'
import { List, arrayMove } from 'react-movable'
import type { Program } from '@/components/Organisms/DIT/types'
import DITProgram from '@/components/Organisms/DIT/DITTree/DITProgram'
import styles from './DITTree.module.scss'
import { OnChangeMeta } from 'react-movable/lib/types'
import { useActions } from '@/hooks'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
type Props = {
  programs: Program[]
  editMode: boolean
}
const DITPrograms: React.FC<Props> = ({ programs, editMode }) => {
  const { ditSetUnsavedTreeChanges, ditSetProgramList } = useActions()
  const [items, setItems] = React.useState(programs || [])
  React.useEffect(() => {
    setItems(programs)
  }, [programs])
  const handleChangeOrdering = ({ oldIndex, newIndex }: OnChangeMeta) => {
    const orderedArr = arrayMove(items, oldIndex, newIndex).map(
      (program, index) => {
        return {
          ...program,
          index,
          hasChanges: index === oldIndex || index === newIndex,
        }
      }
    )
    setItems(orderedArr)
    ditSetUnsavedTreeChanges(true)
    ditSetProgramList(orderedArr)
  }
  return (
    <div className={styles.ProgamTree}>
      {!editMode ? (
        <>
          {programs.map((value) => (
            <DITProgram
              key={value.id}
              program={value}
              editMode={editMode}
            />
          ))}
        </>
      ) : (
        <List
          transitionDuration={0}
          values={items}
          onChange={handleChangeOrdering}
          renderList={({ children, props }) => <div {...props}>{children}</div>}
          renderItem={({ value, props, isDragged }) => {
            return (
              <div
                {...props}
                key={value.id}
                className={styles.DraggedItem}
              >
                {editMode && (
                  <span
                    className={styles.DragCursor}
                    data-movable-handle
                  >
                    <DragIndicatorIcon />
                  </span>
                )}
                <DITProgram
                  program={value}
                  editMode={editMode}
                />
              </div>
            )
          }}
        />
      )}
    </div>
  )
}

export default DITPrograms
