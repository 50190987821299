import { Box, Button, IconButton, Paper, Typography } from '@mui/material'
import IconDelete from '@/components/Atoms/Icons/IconDelete'
import React, { useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import { useDeleteNotesMutation } from '../../services/deviceService'
import { UserTypes } from '@/types/typeUser'
import EditIcon from '@mui/icons-material/Edit'
import { useGetUserProfileQuery } from '@/modules/user/services/userService'
import NotesForm, { useNotesForm } from './NotesForm'
import { Dialog } from '@/components'
import NotesImages from './NotesImages'
const Notes = () => {
  const { data: currentUserData } = useGetUserProfileQuery('0')
  const currentUserType = currentUserData?.data.typeId
  const { id } = useParams()
  const messageListRef = useRef<React.MutableRefObject<HTMLElement>>(null)
  const { data, error } = useNotesForm()
  const [confirmationDialogState, setConfirmationDialogState] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [toRemoved, setToRemoved] = useState<number[]>([])
  const [deleteNotedService] = useDeleteNotesMutation()

  const scrollToBottom = () => {
    const messageList: any = messageListRef.current
    if (messageList) {
      messageList.scrollTop = messageList.scrollHeight
    }
  }

  const toggleEditMode = () => {
    setEditMode(!editMode)
  }
  const handleEditMode = () => {
    toggleEditMode()
  }
  const markAsRemoved = (id: number) => {
    setToRemoved([...toRemoved, id])
  }

  const saveEdits = async () => {
    try {
      await deleteNotedService({
        deviceId: Number(id),
        noteIds: toRemoved,
      })
      setToRemoved([])
      toggleEditMode()
      setConfirmationDialogState(false)
    } catch (e) {
      console.log(e)
    }
  }
  const cancelEdits = () => {
    setToRemoved([])
    toggleEditMode()
  }

  useEffect(() => {
    scrollToBottom()
  }, [error, data])

  const isAdmin =
    currentUserType === UserTypes.superAdmin ||
    currentUserType === UserTypes.admin

  return (
    <Box
      flex={1}
      display='flex'
      flexDirection='column'
    >
      <Box
        display='flex'
        alignItems='center'
      >
        {isAdmin && (
          <IconButton
            disabled={editMode}
            onClick={handleEditMode}
          >
            <EditIcon
              htmlColor={editMode ? '#5ebc69' : 'rgba(0, 114, 188, .7)'}
              fontSize='medium'
            />
          </IconButton>
        )}
        <Typography
          variant='h6'
          fontSize={18}
          marginLeft={1}
          marginBottom={0.5}
          marginTop={0.5}
          color='primary'
        >
          Заметки
        </Typography>
      </Box>
      <Paper
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          py: 1,
          gap: { xss: 0.5, lg: 1 },
        }}
      >
        <Dialog
          open={confirmationDialogState}
          handleClose={() => setConfirmationDialogState(false)}
          handleAgree={saveEdits}
          message='Вы действительно желаете удалить сообщения?'
        />
        <Box
          flex={1}
          maxHeight={
            editMode
              ? 'calc(100vh - 414px)'
              : { xss: 'calc(100vh - 700px)', lg: 'calc(100vh - 520px)' }
          }
          overflow='auto'
          px={1}
          display='flex'
          ref={messageListRef}
        >
          <Box
            flex={1}
            display='flex'
            gap={2}
            flexDirection='column'
          >
            {data?.data?.notes?.map((item) => (
              <Box
                key={item.id}
                display='flex'
                gap={0.5}
                pb={2}
                flexDirection='column'
                style={toRemoved.includes(item.id) ? { display: 'none' } : {}}
              >
                <Box
                  display='flex'
                  gap={1}
                  fontSize={14}
                  alignItems='center'
                >
                  <span style={{ color: 'gray', fontSize: 12 }}>
                    {dayjs(item.createdDate).format('HH:mm DD.MM.YYYY')}
                  </span>
                  <span style={{ color: 'red' }}>{item.userName}</span>
                  {editMode && (
                    <IconButton
                      disabled={toRemoved.includes(item.id)}
                      onClick={() => markAsRemoved(item.id)}
                    >
                      <IconDelete />
                    </IconButton>
                  )}
                </Box>
                <Box sx={{ lineBreak: 'anywhere' }}>
                  <span>{item.message}</span>
                </Box>
                <NotesImages
                  noteId={item.id}
                  images={item.images}
                  date={item.createdDate}
                />
              </Box>
            ))}
          </Box>
        </Box>
        {editMode ? (
          <Box
            pt={2}
            pl={1}
            gap={1}
            display='flex'
            flexDirection='row'
          >
            <Button
              variant='contained'
              disabled={!toRemoved.length}
              onClick={() => setConfirmationDialogState(true)}
            >
              Сохранить
            </Button>
            <Button onClick={cancelEdits}>Отменить</Button>
          </Box>
        ) : (
          <NotesForm />
        )}
        <span style={{ fontSize: 12, paddingTop: 8, paddingLeft: 8 }}>
          Всего сообщений {data?.data?.notes?.length} шт.
        </span>
      </Paper>
    </Box>
  )
}

export default Notes
