import React, { useEffect } from 'react'
import { LoadingButton } from '@mui/lab'
import {
  useGetDitsQuery,
  useUpdateDitsMutation,
  useCreateCopyProgramMutation,
  useEditRelationsMutation,
  useSetActiveDitMutation,
} from '@/components/Organisms/DIT/service'
import {
  getActiveProgram,
  removeIdForNewPrograms,
} from '@/components/Organisms/DIT/helpers'
import { useParams } from 'react-router-dom'
import { useActions, useAppSelector } from '@/hooks'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
import { Box } from '@mui/material'
import { LoaderFullScreen } from '@/ui'
import ModalSave from '@/components/Organisms/DIT/ModalSave/ModalSave'
import DialogChanges from '@/components/Dialog/DialogChanges'
import { notifyError } from '@/components'
import BtnMakeCopy from './BtnMakeCopy'
import BtnSendToDit from './BtnSendToDit'
import BtnSaveRelations from './BtnSaveRelations'

const ActionButtons: React.FC<{ hasConnection: boolean }> = ({
  hasConnection,
}) => {
  const { id } = useParams()
  // const { ditSetActiveProgram } = useActions()
  // const activeProgramIndex = useAppSelector(
  //   ditSliceSelectors.stateDitActiveProgram
  // )
  const activeProgramId = useAppSelector(
    ditSliceSelectors.stateDitActiveProgram
  )
  const relationForm = useAppSelector(ditSliceSelectors.stateRelationForm)
  const switchReduxState = useAppSelector(
    ditSliceSelectors.stateConnectCheckBox
  )
  const form = useAppSelector(ditSliceSelectors.stateRelationForm)

  // const [modalSetDit, setModalSetDit] = React.useState(false)
  // const [setActiveDit, { isLoading: setActiveIsLoading }] =
  //   useSetActiveDitMutation()
  // const [showModal, setShowModal] = React.useState(false)
  // const handleSaveChanges = () => {
  //   setShowModal(true)
  // }
  const hasUnsavedChanges = useAppSelector(ditSliceSelectors.stateHasChanges)
  // const [saveDIts, { isLoading: saveIsLoading }] = useUpdateDitsMutation()
  // const [editRelations, { isLoading: relationsLoading }] =
  //   useEditRelationsMutation()

  const programsState = useAppSelector(ditSliceSelectors.stateDitSlice)
  const { refetch } = useGetDitsQuery(Number(id))
  const activeProgram = getActiveProgram(
    activeProgramId,
    programsState.programList
  )

  // const onSubmit = async () => {
  //   const programs = programsState.programList
  //   await saveDIts({
  //     programs,
  //     deviceId: Number(id),
  //     activeProgramIndex: activeProgram?.index || 1,
  //   })
  // }
  const onCancel = () => {
    refetch()
    // ditSetActiveProgram(activeProgramId)
  }
  // console.log('hasConnection', relationForm)
  return (
    <Box
      display='flex'
      justifyContent='end'
      gap={2}
      mt={5}
    >
      {activeProgram && <BtnMakeCopy newName={activeProgram.name} />}
      {form ? (
        <>
          <BtnSaveRelations />
          <LoadingButton
            disabled={!hasUnsavedChanges}
            variant={'outlined'}
            loading={false}
            onClick={onCancel}
          >
            Отменить
          </LoadingButton>
        </>
      ) : (
        <BtnSendToDit />
      )}
    </Box>
  )
}

export default ActionButtons
