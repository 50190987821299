import { IconButton, InputBase, Paper } from '@mui/material'
import styles from './Units.module.scss'
import SearchIcon from '@mui/icons-material/Search'
import { Dispatch, FC, SetStateAction } from 'react'
import { CustomAutocomplete } from '@/components'
import { useGetUnitsTypesListQuery } from '@/services/unitsListService'
import * as React from 'react'

interface IUnitsHeader {
  filter: number[]
  setFilter: Dispatch<SetStateAction<number[]>>
  searchValue: string
  setSearchValue: Dispatch<SetStateAction<string>>
  setIsSearch: Dispatch<SetStateAction<boolean>>
}

const UnitsHeader: FC<IUnitsHeader> = ({
  filter,
  setFilter,
  searchValue,
  setSearchValue,
  setIsSearch,
}) => {
  const { data: unitsTypesData, isLoading } = useGetUnitsTypesListQuery({
    isAll: true,
  })

  return (
    <div className={styles.HeaderInputs}>
      <div className={styles.HeaderInputWrap}>
        <Paper
          component='form'
          sx={{
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1, height: 44 }}
            placeholder='Поиск системы'
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) => {
              if (
                e.code === 'Enter' ||
                e.charCode === 13 ||
                e.code === 'NumpadEnter'
              ) {
                e.preventDefault()
                setIsSearch((prevState) => !prevState)
              }
            }}
            inputProps={{
              maxLength: 50,
            }}
          />
          <IconButton
            onClick={() => setIsSearch((prevState) => !prevState)}
            type='button'
            sx={{ p: '10px' }}
            aria-label='search'
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
      <div className={styles.HeaderInputWrap}>
        <CustomAutocomplete
          disabled={isLoading}
          options={unitsTypesData?.data ?? []}
          filter={filter}
          setFilter={setFilter}
          nameForStorage='filterValueUserUnits'
        />
      </div>
    </div>
  )
}

export default UnitsHeader
