import React from 'react'
import cn from 'classnames'
import styles from '@/components/Organisms/DIT/DITTree/DITTree.module.scss'
import { IconButton } from '@mui/material'
import IconDelete from '@/components/Atoms/Icons/IconDelete'
import IconBell from '@/components/Atoms/Icons/IconBell'
import type { Program, Slide } from '@/components/Organisms/DIT/types'
import IconDown from '@/components/Atoms/Icons/IconDown'
import IconRight from '@/components/Atoms/Icons/IconRight'
import IconModule from '@/components/Atoms/Icons/IconModule'
import DITItem from '@/components/Organisms/DIT/DITTree/DITItem'
import { useActions, useAppSelector } from '@/hooks'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
import DialogChanges from '@/components/Dialog/DialogChanges'
type Props = {
  program: Program
  programId: number
  editMode: boolean
  slide: Slide
}
const DITSlide: React.FC<Props> = ({ slide, editMode, programId, program }) => {
  const [modalRemove, setModalRemove] = React.useState(false)
  const activeProgram = useAppSelector(ditSliceSelectors.stateDitActiveProgram)
  const isDisabled = useAppSelector(
    ditSliceSelectors.stateDitActiveProgramDisabled
  )
  const activeSlide = useAppSelector(ditSliceSelectors.stateDitActiveSlide)
  const activeSlideItem = useAppSelector(
    ditSliceSelectors.stateDitActiveSlideItem
  )
  const [slidesCollapsed, setSlidesCollapsed] = React.useState(false)
  const { ditSetActiveSlide, ditRemoveSlide, ditUpdateSlideName } = useActions()
  const isActive =
    `${programId}${slide.id}` === `${activeProgram}${activeSlide}` &&
    activeSlideItem === null
  const toggleSlidesCollapsed = () => {
    setSlidesCollapsed(!slidesCollapsed)
  }
  const handleActiveSlide = () => {
    ditSetActiveSlide({
      slideId: slide.id,
      programId: programId,
    })
  }
  const removeHandler = () => {
    setModalRemove(true)
  }
  const approvedRemoveHandler = () => {
    ditRemoveSlide({
      slideId: slide.id,
      programId: programId,
    })
    setModalRemove(false)
  }
  const handleChangeName = (e: any) => {
    ditUpdateSlideName({
      name: e.target.value,
      programId: programId,
      slideId: slide.id,
    })
  }
  return (
    <>
      <div
        key={slide.id}
        className={!editMode ? styles.SlidesWrap : styles.SlidesWrapGrow}
      >
        <DialogChanges
          open={modalRemove}
          handleAgree={approvedRemoveHandler}
          handleClose={() => setModalRemove(false)}
          handleDisAgree={() => setModalRemove(false)}
          title={'Удалить элемент?'}
          btnSaveTitle={'Удалить'}
          message={
            'ВНИМАНИЕ! Если элемент содержит вложения - они будут также удалены ?'
          }
        />
        <div className={styles.ProgramRow}>
          <div
            className={cn(styles.ProgramRowLeft, {
              [styles.ProgramTitleActive]: isActive,
            })}
          >
            <span
              style={{
                visibility: slide.items.length ? 'visible' : 'hidden',
              }}
              onClick={toggleSlidesCollapsed}
              className={styles.ToggleArrow}
            >
              {slidesCollapsed ? <IconRight /> : <IconDown />}
            </span>

            <IconModule />
            {!program.active && editMode ? (
              <>
                <input
                  defaultValue={slide.name}
                  onChange={handleChangeName}
                  className={styles.EditableNameInput}
                  type='text'
                />
              </>
            ) : (
              <span onClick={handleActiveSlide}>{slide.name}</span>
            )}
          </div>
          {!program.active && editMode && (
            <IconButton onClick={removeHandler}>
              <IconDelete />
            </IconButton>
          )}
        </div>
        {!slidesCollapsed &&
          slide.items.map((item) => (
            <DITItem
              key={item.id}
              program={program}
              programId={programId}
              slideId={slide.id}
              item={item}
              editMode={editMode}
            />
          ))}

        {/*{!slidesCollapsed && slide.showTime && (*/}
        {/*  <div className={cn(styles.ProgramRow, styles.ProgramRowNoEffects)}>*/}
        {/*    <div*/}
        {/*      className={cn(styles.ProgramRowLeft, styles.ProgramRowLeftBell)}*/}
        {/*    >*/}
        {/*      <IconBell /> Обновление {slide.showTime} сек*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </>
  )
}

export default DITSlide
