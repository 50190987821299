import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
import { useActions, useAppSelector } from '@/hooks'
import styles from './DIT.module.scss'
import DITTree from '@/components/Organisms/DIT/DITTree'
import DITConstructor from '@/components/Organisms/DIT/DITConstructor'
import type { Program } from './types'
import { LoadingButton } from '@mui/lab'
import IconAddDoc from '@/components/Atoms/Icons/IconAddDoc'
import { getActiveProgram, getActiveSlide } from './helpers'
import { useSetActiveDitMutation } from '@/components/Organisms/DIT/service'
import ModalSave from '@/components/Organisms/DIT/ModalSave/ModalSave'
import { useParams } from 'react-router-dom'
import DialogChanges from '@/components/Dialog/DialogChanges'
import { LoaderFullScreen } from '@/ui'
import Slide from '@/components/Organisms/DIT/Slide/Slide'
import DITConnectionForm from '@/components/Organisms/DIT/DITConnectionForm/DITConnectionForm'
import ActionButtons from '@/components/Organisms/DIT/ActionButtons/ActionButtons'

type Props = {
  disableButtons: boolean
  data: Program[]
}
const DIT: React.FC<Props> = ({ data, disableButtons }) => {
  const { id } = useParams()
  // const [modalSetDit, setModalSetDit] = React.useState(false)
  const programsState = useAppSelector(ditSliceSelectors.stateDitSlice)
  const programs = useAppSelector(ditSliceSelectors.stateDitPrograms)
  const activeSlideId = useAppSelector(ditSliceSelectors.stateDitActiveSlide)
  const activeSlideItem = useAppSelector(
    ditSliceSelectors.stateDitActiveSlideItem
  )
  const activeProgramIndex = useAppSelector(
    ditSliceSelectors.stateDitActiveProgram
  )
  const { ditConnectCheckBox, setConnectionForm } = useActions()

  const activeProgram = getActiveProgram(activeProgramIndex, programs)
  useEffect(() => {
    let val = null
    if (activeProgram?.relationShip) {
      // console.log(activeProgram)
      val = {
        switchState: true,
        eventsList: activeProgram.relationShip?.events || [],
        sourceId: activeProgram.relationShip?.deviceId,
      }
    }
    setConnectionForm(val)
  }, [activeProgram])
  // const activeSlide = getActiveSlide(activeSlideId, activeProgram?.slides)
  // const [setActiveDit, { isLoading: setActiveIsLoading }] =
  //   useSetActiveDitMutation()
  // const [showModal, setShowModal] = React.useState(false)

  // const handleSaveChanges = () => {
  //   setShowModal(true)
  // }
  // const handleActiveProgram = () => {
  //   setModalSetDit(true)
  // }
  // const approvedSendDit = async () => {
  //   if (activeProgramIndex && activeProgram?.id) {
  //     await setActiveDit({
  //       deviceId: Number(id),
  //       programId: activeProgram?.id,
  //     })
  //   }
  //   setModalSetDit(false)
  // }
  const isProgramSelected = activeProgram && !activeSlideId && !activeSlideItem
  const activeProgramSlides = activeProgram?.slides?.map((slide) => slide) || []
  return (
    <div className={styles.Wrapper}>
      {/*<LoaderFullScreen open={setActiveIsLoading} />*/}
      {/*<ModalSave*/}
      {/*  showModal={showModal}*/}
      {/*  setShowModal={setShowModal}*/}
      {/*/>*/}
      <div className={styles.Left}>
        {programsState.hasChanges && (
          <div
            className={styles.LeftDisabled}
            // onClick={handleSaveChanges}
          />
        )}
        <DITTree
          programs={data}
          disableButtons={disableButtons}
        />
      </div>
      <div className={styles.Right}>
        {programsState.treeHasChanges && (
          <div className={styles.RightDisabled} />
        )}
        <div className={styles.PreviewWrapper}>
          {activeProgram &&
            !!activeProgram?.slides.length &&
            !activeSlideId && (
              <DITConnectionForm
                programId={activeProgramIndex || 0}
                relationShip={activeProgram.relationShip}
              />
            )}
          {isProgramSelected &&
            activeProgramSlides.map((slide) => (
              <div
                key={slide.id}
                className={styles.Preview}
              >
                <span>
                  <strong>{slide.name}:</strong>&nbsp;
                  {slide.flicker
                    ? `Мигание: ${slide.showTime} сек`
                    : `Статичный ${slide.showTime} сек`}
                </span>
                <img
                  src={slide.preview}
                  loading={'lazy'}
                  alt=''
                />
              </div>
            ))}
        </div>
        {!activeProgram && !programs.length && (
          <div className={styles.NoPrograms}>
            Список программ пуст. <br />
            Создайте программу
            <div>
              <IconAddDoc />
            </div>
          </div>
        )}
        <Slide activeProgram={activeProgram} />

        {activeSlideItem && (
          <DITConstructor
            width={programsState.pageData.width}
            height={programsState.pageData.height}
          />
        )}
        <br />
        <Box
          display='flex'
          justifyContent='end'
          gap={2}
        >
          {activeProgram &&
            !activeSlideId &&
            !!activeProgram?.slides.length && (
              <ActionButtons
                hasConnection={Boolean(activeProgram.relationShip)}
              />
            )}
        </Box>
      </div>
    </div>
  )
}

export default DIT
