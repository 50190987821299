import * as React from 'react'
import IconHumidity from '@/components/Atoms/Icons/IconHumidity'
import IconPressure from '@/components/Atoms/Icons/IconPressure'
import IconTemperature from '@/components/Atoms/Icons/IconTemperature'
import IconArrowUp from '@/components/Atoms/Icons/IconArrowUp'
import IconArrowDown from '@/components/Atoms/Icons/IconArrowDown'
import IconMinus from '@/components/Atoms/Icons/IconMinus'
import { FC } from 'react'
import styles from './Forecast.module.scss'
import type { DeviceDetail } from '@/modules/device-module/types/deviceType'

interface Props {
  data?: DeviceDetail['meteo']
}

enum SensorDirection {
  Up = 'Растет',
  Down = 'Падает',
  NoChanges = 'Не меняется',
}

const getDirection = (value: number) => {
  if (value > 0) return SensorDirection.Up
  if (value < 0) return SensorDirection.Down
  return SensorDirection.NoChanges
}

const getImageUrl = (value: number) => {
  if (value > 0) return <IconArrowUp />
  if (value < 0) return <IconArrowDown />
  return <IconMinus />
}

const Forecast: FC<Props> = ({ data }) => {
  if (!data || !data?.trendCalculated) return null
  const { temperatureTrend, humidityTrend, pressureTrend } = data

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Item}>
        <IconPressure />
        <span>Давление</span>
        <span>{getImageUrl(pressureTrend)}</span>
        <span>{getDirection(pressureTrend)}</span>
      </div>
      <div className={styles.Item}>
        <IconTemperature />
        <span>Температура</span>
        <span>{getImageUrl(temperatureTrend)}</span>
        <span>{getDirection(temperatureTrend)}</span>
      </div>
      <div className={styles.Item}>
        <IconHumidity />
        <span>Влажность</span>
        <span>{getImageUrl(humidityTrend)}</span>
        <span>{getDirection(humidityTrend)}</span>
      </div>
    </div>
  )
}

export default Forecast
