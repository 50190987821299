import React, { FC } from 'react'
import styles from './NotesImages.module.scss'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import Captions from 'yet-another-react-lightbox/plugins/captions'
import 'yet-another-react-lightbox/plugins/captions.css'
import { useMediaQuery } from '@mui/material'
import type { NoteImage } from '@/modules/device-module/types/description'

type Props = {
  noteId: number
  images: NoteImage[]
  date: string
}

type LightboxImage = {
  thumbnail: string
  src: string
  alt?: string
  description?: string
  title?: string
}

const getImages = ({ images, date }: Pick<Props, 'images' | 'date'>) => {
  const res: LightboxImage[] = []
  const formattedDate = new Date(date)
  images.forEach((image, index) => {
    res.push({
      src: image.url,
      thumbnail: image.thumbnail,
      title: `Изображение ${index + 1} из ${images.length} - ${formattedDate.toLocaleDateString()}`,
    })
  })
  return res
}

const NotesImages: FC<Props> = ({ noteId, images, date }) => {
  if (!noteId || !images.length) return null
  const imagesLightbox: LightboxImage[] = getImages({ images, date })
  const [index, setIndex] = React.useState(0)
  const [open, setOpen] = React.useState(false)

  const handleOpen = (index: number) => {
    setIndex(index)
    setOpen(true)
  }
  const handleClose = () => setOpen(false)
  const isMobile = useMediaQuery('(max-width:768px)')

  return (
    <div className={styles.Wrapper}>
      {open && (
        <Lightbox
          open={open}
          close={handleClose}
          index={index}
          slides={imagesLightbox}
          plugins={[Captions]}
          className={isMobile ? styles.GalleryMobile : styles.Gallery}
        />
      )}
      {imagesLightbox.map((image, index) => (
        <img
          onClick={() => handleOpen(index)}
          className={styles.Image}
          key={image.thumbnail}
          src={image.thumbnail}
        />
      ))}
    </div>
  )
}

export default NotesImages
